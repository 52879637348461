html,
body,
#root {
  height: 100%;
  font-family: "Noto Sans", sans-serif;
}

body {
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  color: #656565;
  background-color: #fff;
  font-variant: tabular-nums;
  line-height: 1.5715;
  font-feature-settings: "tnum", "tnum";
}

.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

a {
  color: #21c2f8;
}

.social .fa {
  font-family: FontAwesome;
  font-weight: normal;
}

a {
  color: #1890ff;
  text-decoration: none;
  background-color: initial;
  outline: none;
  cursor: pointer;
  transition: color .3s;
  -webkit-text-decoration-skip: objects;
}

.fa {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-btn-primary {
  background: #21C2F8 !important;
  border-color: #21C2F8 !important;
}

.right-align {
  text-align: right !important;
}

.center-align {
  text-align: center !important;
}

.ant-btn .anticon svg {
  margin-top: -6px;
}

.ant-tabs-nav-wrap {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 0 16px;
  font-weight: 600;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 600 !important;
}

.ant-tabs-top>.ant-tabs-nav::before {
  border: none !important;
}

.ant-input-search .ant-input-wrapper>input {
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.03);
  height: 40px;
}

.ant-input-search .ant-input-wrapper button {
  border-radius: 0 8px 8px 0 !important;
  height: 40px;
}

#defaultmenu {
  padding: 0 !important;
}

.navbar-default{
  margin-top: 28px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.1);
}

body {
  overflow: overlay;
}

.ant-card-cover {
  border: 1px solid #f0f0f0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-weight: 600;
  text-rendering: optimizeLegibility;
}

.subtitle{
  opacity: 0.7;
  margin-bottom: 0;
}

.ant-card{
  box-shadow: 0 0 4px rgba(0,0,0,0.2);
}

.lead{
  font-family: 'Droid Serif', Georgia, "Times New Roman", serif !important;
    font-size: 15px;
    font-style: italic;
    font-weight: 300;
    line-height: 1;
    padding: 15px 0;
}

.title hr{
  border-top: 1px solid #d4d4d4;
    position: relative;
    max-width: 235px;
    margin: 0 auto;
}

.text-center{
  text-align: center;
}

.effect-slide-bottom.in{
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: all 0.7s ease-in-out 0s;
}

.service-square{
  margin: 40px 0 20px;
}

.service-square-icon i{
  font-size: 18px;
}

.service-square-icon{
  background-color: #21C2F8;
  width: 65px;
    height: 65px;
    border-radius: 3px;
    line-height: 65px;
    text-align: center;
    margin: 3px 15px 15px 0;
}

.alignleft {
  float: left;
}

.title h6{
  font-weight: 600;
}

.service-square-icon .anticon{
  color: white;
  font-size: 18pt;
  position: relative;
  top: -2px;
}

.btn-success{
  background: #75d428 !important;
    background-image: linear-gradient(180deg,#75d428 0,#4ead00) !important;
    background-repeat: repeat-x;
    border-color: #4ead00 !important;
    color: #fff !important;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff75d428",endColorstr="#ff4ead00",GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
    text-shadow: 0 -1px 0 rgb(0 0 0 / 25%);
}