.search-profile-component .loading-logo{
    width: 100%;
    height: 180px;
    background-color: lightgrey;
}

.search-profile-component .ant-card-cover{
    height: 180px;
    display:flex;
}

.search-profile-component .ant-card-cover img{
    margin: auto;
    max-width: 100%;
    max-height: 100%;
}

