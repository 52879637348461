@media (max-width:768px) {
    .navbar-mobile_button_bar {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
    }
  
    .mobile_navigation-list {
      font-size: 16px;
      font-weight: normal;
    }
  
    .header-mobile {
      display: flex;
    }
  
    #mobile_navigation.open {
      left: 0%;
    }
  }
  
  @media (width:768px) {
    .header-mobile {
      display: none;
    }
  }
  
  @media (min-width: 350px) {
    .mobile_navigation-search {
      margin-right: 41px;
    }
  }
  
  @media (max-width: 350px) {
    .mobile_navigation-search {
      margin-right: 10px;
    }
  
    .mobile-menu {
      margin-right: 10px;
    }
  }
  
  .mobile_navigation-list {
    margin-bottom: 0px !important;
  }
  
    .mobile_navigation-list > li {
      margin-top: 16px;
    }
  
  div {
    font-family: "Noto Sans", sans-serif;
  }
  
  .mobile_navigation-item__pricing {
    padding: 26px 0px 26px 0px;
    margin: 0px 28px;
    border-top: solid 1px #B9BFC3;
    border-bottom: solid 1px #B9BFC3;
    font-size: 18px !important;
  }
  
  .mobile_navigation-item__rectangle {
    height: 26px;
    background-color: #F3F4F4
  }
  
  .mobile_navigation-item__pricing > a {
    color: #505D64;
    font-weight: bold;
  }
  
  .navbar-bars {
    color: #616161;
    z-index: 90;
  }
  
    .navbar-bars.open:before {
      content: "\f00d" !important;
    }
  
    .navbar-bars:before {
      content: "\f0c9";
      display: block;
      font-family: 'FontAwesome';
      font-size: 32px;
      width: 32px;
    }
  
  .mobile_navigation-search {
  }
  
    .mobile_navigation-search:before {
      content: "\f002";
      display: block;
      color: #505D64;
      font-family: 'FontAwesome';
      font-size: 24px;
    }
  
  .mobile-search {
    display: flex;
    align-content: center;
    margin-top: 6px;
  }
  
  .mobile_navigation-menu {
    border: 0px;
    background: none;
  }
  
  .mobile_navigation-item__top {
    background-color: yellow;
  }
  
  .mobile_navigation-item {
    font-family: "Noto Sans", sans-serif;
    font-size: 18px;
  }
  
  .mobile_navigation-collapsible {
  }
  
  .mobile_navigation-item > a {
    color: #616161 !important;
  }
  
  .mobile_navigation-collapsible > div {
    padding-left: 50px;
    padding-bottom: 8px;
    padding-top: 8px;
  }
  
  /*.mobile_navigation-search {
    margin: auto 15px auto auto
  }*/
  
  .mobile_navigation-button__yellow {
    background-color: #FCCF0A;
    font-weight: bold;
    color: #2F3538;
    font-size: 24px;
    padding: 8px 20px;
    margin: 26px 30px;
    border: none;
    text-align: center;
  }
  
    .mobile_navigation-button__yellow > a {
      color: #2F3538;
    }
  
  .mobile_navigation-button__white {
    background-color: #FFFFFF;
    font-weight: bold;
    font-size: 24px;
    color: #505D64;
    padding: 18px 20px;
    margin: 0px 30px 12px 30px;
    border: none;
    text-align: center;
  }
  
    .mobile_navigation-button__white > input {
      background-color: #FFFFFF;
      border: none;
      color: #505D64;
      font-weight: bold;
      font-size: 24px;
      width: 100%;
    }
  
    .mobile_navigation-button__white > a {
      color: #505D64;
      width: 100%;
    }
  
  .mobile_navigation-button__blue {
    background-color: #009AE1;
    font-weight: bold;
    color: #FFFFFF;
    font-size: 24px;
    padding: 8px 20px;
    margin: 26px 30px;
    border: none;
    text-align: center;
  }
  
    .mobile_navigation-button__blue > a {
      color: #FFFFFF;
    }
  
  .mobile_navigation-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
    .mobile_navigation-header.open {
      background-color: #F3F4F4;
    }
  
  .mobile_navigation-list > li > a {
    color: #292723;
  }
  
    .mobile_navigation-list > li > a:hover {
      font-weight: bold;
      text-decoration-line: underline;
      color: #0077BA;
    }
  
  .mobile-column {
    margin-top: 2em
  }
  
  mobile-column__top {
    margin-top: 8px !important;
  }
  
  .header-button {
    font-weight: bold !important;
    color: #505D64 !important;
    font-size: 18px !important;
    border: none;
    background: transparent;
    flex-grow: 1;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 26px 0px 26px 0px;
    margin: 0px 28px;
    border-top: solid 1px #B9BFC3;
  }
  
  
  .header.open {
    background-color: #F5F5F5
  }
  
  .chevron-right:before {
    transition: transform 0.3s ease;
    content: "\f054";
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    background: none repeat scroll 0 0 rgba(255, 255, 255, 0.3);
    color: #B9BFC3;
    display: block;
    font-family: 'FontAwesome';
    font-size: 20px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    height: 27px;
    line-height: 27px;
    text-transform: none;
    width: 27px;
  }
  
  .chevron-right.rotated:before {
    content: "\f078"
  }
  
  .mobile-category_header {
    font-size: 16px;
    font-weight: bold;
    color: #0077BA;
    text-transform: capitalize;
  }
  
  .mobile-category_header__black {
    font-size: 16px;
    font-weight: bold;
    color: #2F3538;
    margin-top: 10px;
  }
  