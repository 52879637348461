.partner-buttons{
    width: 100%;
    text-align: center;
    margin-top: 16px;
}

.partner-buttons button{
    margin: 8px;
}

.network-image{
    background: url("/public/network.jpg");
    height: 150px;
    background-size: cover;
}

.network-image h3{
    color: white;
}

.network-image .ant-card-body{
    padding: 0;
}

.network-image .title {
    height: 60px;
    padding: 16px;
    background: linear-gradient(180deg,rgba(0,0,0,.7),rgba(0,0,0,.6) 55%,transparent);
}

.partners-image{
    background: url("/public/partners.jpg");
    height: 150px;
    background-size: cover;
}

.partners-image h3{
    color: white;
}

.partners-image .ant-card-body{
    padding: 0;
}

.partners-image .title {
    height: 60px;
    padding: 16px;
    background: linear-gradient(180deg,rgba(0,0,0,.7),rgba(0,0,0,.6) 55%,transparent);
}