@media (max-width:768px) {
    .header-breadcrumb {
      display: none !important;
    }
  
    .header-account {
      display: none;
    }
  
    .header-collapse__mobile {
      display: none !important;
    }
  
    .social-top {
      display: none;
    }
  
    .header {
      height: 72px;
      min-height: 72px !important;
      padding: 0px !important;
    }
  
    .header-navbar {
      height: 72px;
    }
  
    .header-logo {
      height: 30px;
    }
  
    .header-mobile {
      flex: 1;
      justify-content: end;
    }
  
    .navbar-mobile_button_bar {
      justify-self: flex-end;
    }
  
    .header-container {
      padding: 0 1em;
    }
  }
  
  @media (max-width: 1500px) {
    .header-collapse {
      display: none !important;
    }
  }
  
  @media (max-width: 1000px) {
    .header-collapse__md {
      display: none !important;
    }
  }
  
  
  @media (min-width: 768px) {
    .header-account {
      display: flex;
    }
  
    .mobile_navigation {
      display: none;
    }
  
    .dropdown-header {
      margin-top: 10px;
    }
  
    .navbar-mobile_button_bar {
      display: none;
    }
  
    .mobile_navigation-search {
      display: none;
    }
  
    .header {
      height: 80px;
      min-height: 80px !important;
      align-content: center;
      padding: 0px !important;
      margin: 0px !important;
    }
  
    .header-mobile {
      display: none;
    }
  
    .header-container {
      padding: 0 1em;
    }
  }
  
  @media (max-width:1100px) and (min-width:768px) {
    .header-container {
      padding: 0 2em;
    }
  }
  
  
  .header {
    background: #ffffff;
    
    position: relative;
  }
  
  .header-container {
    max-width: 1500px !important;
    margin: 0 auto;
  }
  
  .header-navbar_container {
    padding-left: 2%;
    padding-right: 2%;
    height: 80px;
  }
  
  ul.header-navbar {
    margin: 0px;
  }
  
  .navbar-tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    width: auto;
    padding: 0px 15px;
  }
  
  .navbar-default {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  
  .header-navbar {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  
  .breadcrumb {
    display: inline;
  }
  .header .header-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
    .header .header-navbar .dropdown-menu {
      position: absolute;
      
    }
  
      .header .header-navbar .dropdown-menu .container-fluid {
        padding: 20px 30px;
      }
  
  .navbar-default .navbar-toggle .icon-bar {
    background-color: #505D64;
  }
  
  .navbar-toggle {
    margin-right: 0px !important;
  }
  
  .navbar-default .navbar-toggle {
    border: none !important;
  }
  
  
  button.navbar-toggle {
    align-self: flex-end;
  }
  
  .tab-dropdown {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, .25) !important;
  }
  
  .logo {
    float: left;
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;
    text-shadow: none;
  }
  
  .dropdown-ruler {
    margin-top: 0px !important;
    margin-bottom: 10px !important;
    border-top: 1px solid #B9BFC3 !important;
  }
  
  .dropdown-logout > input {
    border: none;
    background: none;
  }
  
    .dropdown-logout > input:hover {
      text-decoration-line: underline;
      font-weight: bold;
      color: #0077BA;
    }
  
  .header-navbar.fhmm {
    position: relative;
  }
  
  .dropdown-menu.fullwidth {
    width: 100%;
    left: 0;
    right: 0;
  }
  
  
  .dropdown-header {
    font-size: 16px;
    margin-bottom: 2px;
  }
  
  .dropdown-menu {
    margin: 0px auto 0px auto !important;
  }
  
  .dropdown-menu_list > li > a:hover {
    text-decoration-line: underline;
    font-weight: bold;
    color: #0077BA;
  }
  
  .dropdown-menu_list > li {
    margin-bottom: 10px;
  }
  
  .button {
    display: inline-block;
    padding: 0.75em 1.25em;
    font-weight: bold;
    font-size: 16px;
    white-space: nowrap;
  }
  
  .dropdown-cta > .button {
    margin-bottom: 1.25em
  }
  
  .dropdown-menu {
    font-size: 16px !important;
  }
  
  .dropdown-category_header.dropdown-title {
    color: #2f3538;
    line-height: 1.5;
    margin-bottom: 15px;
  }
  
  p.dropdown-desc {
    font-size: 16px;
    font-weight: 400;
    max-width: 40ch;
  }
  
  .button-blue {
    background: #009ae1;
    color: #fff !important;
  }
  
    .button-blue:hover, .button-blue:focus {
      background: #0077ba;
    }
  
  .button-yellow {
    background: #fccf0a;
    color: #2f3538 !important;
  }
  
    .button-yellow:hover, .button-yellow:focus {
      background: #fa9e00;
    }
  
  .button-blue-outline {
    border: 2px solid;
    border-color: #0077ba;
    color: #0077ba !important;
  }
  
    .button-blue-outline:hover, .button-blue-outline:focus {
      background: #0077ba;
      color: #fff !important;
    }
  
  .dropdown-cta.border-right, .dropdown-cta.border-left {
    color: #B9BFC3;
    border-right: 1px solid;
    border-left: 1px solid;
  }
  
  .dropdown-category_header {
    font-size: 16px;
    font-weight: bold;
    color: #0077BA;
    margin-top: 10px;
    text-transform: uppercase;
  }
  
  .dropdown-category_header__black {
    font-size: 16px;
    font-weight: bold;
    color: #2F3538;
    margin-top: 10px;
    text-transform: uppercase;
  }
  
  .dropdown-menu_list {
    margin-left: 0 !important;
  }
  
  .dropdown-search {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }
  
  li > .header-tab {
    font-weight: bold !important;
    color: #505D64 !important;
    font-size: 16px !important;
  }
  
  .log-out {
    border-left: none;
    border-right: none;
    border-bottom: none;
    background: none;
  }
  
  .header-tab {
    font-weight: bold !important;
    color: #505D64 !important;
    font-size: 16px !important;
    font-family: "Noto Sans", sans-serif;
    display: flex;
    padding: 0.75em 1em;
    margin: 0.75em 0em;
    text-align: center;
    border-top: solid 3px rgba(255,255,255,0);
  }
  
    .header-tab:hover {
      font-weight: bold !important;
      color: #0077BA !important;
      font-size: 16px !important;
      border-top: solid #B9BFC3 3px;
    }
  
  .account-bar {
    display: flex;
    flex-direction: row;
  }
  
  li.dropdown > a {
    font-weight: bold !important;
    color: #616161 !important;
    font-size: 16px !important;
  }
  
    li.dropdown > a:hover {
      color: #0077BA !important;
    }
  
  .header-button__yellow {
    background-color: #FCCF0A;
    font-weight: bold;
    padding: 5px 20px;
    display: inline-block;
    font-size: 16px !important;
    border: none;
    margin: 5px;
    white-space: nowrap;
  }
  
  
    .header-button__yellow > a:hover {
      color: #0077BA !important;
    }
  
  
  .header-button > a {
    font-weight: bold !important;
    color: #616161 !important;
    font-size: 16px !important;
  }
  
    .header-button > a:hover {
      color: #0077BA !important;
    }
  
  .header-button__yellow > a {
    color: #2F3538;
  }
  
  .header-button__white {
    background-color: transparent;
    font-weight: bold;
    padding: 5px 20px;
    border: solid 3px #D7D8D9;
    margin: 5px;
    font-size: 16px !important;
  }
  
  .header-account {
    flex-wrap: nowrap;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;
  }
  
  .header-searchicon {
    color: #616161;
    z-index: 90;
    float: right;
    margin: 27px 10px 27px 0px;
  }
  
    .header-searchicon:before {
      content: "\f002";
      display: block;
      font-family: 'FontAwesome';
      font-size: 16px;
    }
  
  .header-button__white > a {
    color: #60717B;
  }
  
  .header-breadcrumb {
    background: #EBECEC;
    padding: 10px;
  }
  
  .header-ruler {
    height: 40px;
    margin-left: 10px;
    width: 1px;
    margin-right: 10px;
    background: #B9BFC3;
  }
  
  
  
  
  #mobile_navigation {
    position: fixed;
    width: 100%;
    height: 100%;
    background: white;
    left: -100%;
    transition: left 0.3s;
    z-index: 20;
    top: 70px;
    overflow-y: scroll;
  }
  
  #linkAdministration {
    margin-left: 30px;
  }
  
  .search-textbox {
    width: 465px;
    height: 45px;
    border: solid 1px #C2C7CA !important;
    border-radius: 6px;
    font-size: 30px;
  }
  
  .search-button {
    color: #FFFFFF !important;
    background-color: #009AE1;
    font-size: 16px;
    font-weight: bold;
    padding: 11px 50px;
    margin-left: 24px;
    line-height: 23px;
    border: none;
  }
  
  .dropdown-search {
    padding: 30px 0px;
  }
  
  .search-button > i {
    margin-right: 10px;
    color: #FFFFFF;
  }
  